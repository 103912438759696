export class EvaluationType {
    public static values = [
        //"Maltreatment Investigation",
        "Audit",
        "CHOW",
        "Common Project Activities",
        "Complaints",
        //"Compliance Investigation",
        "Initial Inspection",
        //"Maltreatment Investigation",
        "Scheduled Inspection"
    ];
}